import { Children, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw, { theme } from 'twin.macro';

import TimesIcon from '@assets/icons/TimesIcon';
import useEventListener from '@hooks/useEventListener';
import useMediaQuery from '@hooks/useMediaQuery';
import useOverflowBody from '@hooks/useOverflowBody';
import { down } from '@utils/screens';

import mapPropsToChild from './mapPropsToChild';
import coreStyles from './Modal.styles';

const DEFAULT_SIZE = 'sm';

const Modal = ({
  children,
  isOpen = false,
  onClose,
  disableClose = false,
  styles = {},
  desktopSize = DEFAULT_SIZE,
  'data-cy': dataCy = '',
}) => {
  const selector = '#modalPortal';

  const closeBasketKeyboard = useCallback(e => {
    if (e.keyCode === 27 && !disableClose) {
      onClose();
    }
  }, []);

  useEventListener('keydown', closeBasketKeyboard, false);
  useOverflowBody([isOpen], isOpen);
  const isDownSm = useMediaQuery(down('sm'));

  const handleClose = () => {
    if (!disableClose) {
      document.body.style.removeProperty('overflow');
      onClose();
    }
  };

  const childrenWithProps = Children.map(children, child =>
    mapPropsToChild(child, {
      onClose: handleClose,
      disableClose,
      size: isDownSm ? 'sm' : desktopSize,
    })
  );

  const mergedWrapperStyles = [
    tw`z-10 overflow-auto bg-white shadow-lg w-full sm:w-auto overscroll-none`,
    isDownSm
      ? tw`absolute bottom-0 rounded-t-lg`
      : tw`relative mt-12 mx-4 rounded-lg`,
    css`
      max-width: 800px;
      max-height: calc(100% - 32px);
      @media (min-width: ${theme`screens.md`}) {
        max-height: 90vh;
        min-width: 500px;
      }
    `,
    styles.wrapper,
  ];

  const ModalComponent = (
    <div
      tw="fixed inset-0 flex justify-center z-50 items-start pointer-events-none"
      css={styles.container}
      role="dialog"
      aria-modal="true"
      {...(!isEmpty(dataCy) ? { 'data-cy': dataCy } : {})}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="0"
            tw="bg-black w-screen h-screen absolute z-0 pointer-events-auto"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 0.6 },
            }}
            onClick={handleClose}
            initial="hidden"
            animate="visible"
            exit="hidden"
          />
        )}
        {isOpen && (
          <motion.div
            key="1"
            tw="pointer-events-auto"
            variants={{
              hidden: {
                opacity: 0,
                transform: isDownSm ? 'translateY(100%)' : 'scale(0)',
                transition: {
                  duration: 0.3,
                },
              },
              visible: {
                opacity: 1,
                transform: isDownSm ? 'translateY(0%)' : 'scale(1)',
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            css={mergedWrapperStyles}
          >
            {childrenWithProps}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  return createPortal(ModalComponent, document.querySelector(selector));
};

const Header = ({
  children,
  noBorder = false,
  onClose = undefined,
  size = DEFAULT_SIZE,
  disableClose = false,
  styles = {},
}) => (
  <header css={coreStyles.header({ noBorder, size, styles: styles.header })}>
    {children && (
      <h2 css={styles.heading} tw="mb-0 break-words">
        {children}
      </h2>
    )}
    {!disableClose && (
      <button
        type="button"
        tw="border-transparent text-gray-2 transform duration-300 flex items-center justify-center rounded-lg absolute cursor-pointer outline-none hover:text-gray-3 focus-visible:(outline-none ring-gray-1) right-6 top-6"
        css={styles.closeButton}
        onClick={onClose}
        data-cy="modal__close"
      >
        <TimesIcon tw="w-4 fill-current" />
      </button>
    )}
  </header>
);

const Content = ({ cssExtend = {}, children, size = DEFAULT_SIZE }) => (
  <main css={[coreStyles.content({ size }), cssExtend]}>{children}</main>
);

const Footer = ({ children, size = DEFAULT_SIZE }) => (
  <footer css={coreStyles.footer({ size })}>{children}</footer>
);

Modal.displayName = 'Modal';
Header.displayName = 'Header';
Content.displayName = 'Content';
Footer.displayName = 'Footer';

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;
