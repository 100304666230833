import 'regenerator-runtime/runtime';

import getConfig from 'next/config';
import Router from 'next/router';
import { appWithTranslation } from 'next-i18next';

import { useEffect } from 'react';
import { useStore } from 'react-redux';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setAppConfig } from '@store/config/config.slice';
import { setNativeAppConfig } from '@store/nativeAppConfig/nativeAppConfig.slice';
import { wrapper } from '@store/store';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import {
  dehydrate,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { getCookie, setCookie } from 'cookies-next';
import deepmerge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';
import NProgress from 'nprogress';

import TailwindGlobalStyles from '@components/TailwindGlobalStyles';
import { BASE_URL } from '@constants/endpoint';
import TIME from '@constants/time';
import { getLanguages } from '@hooks/useLanguages';
import { getOrderForm } from '@hooks/useOrderForm';
import axios from '@utils/axiosInstance';
import getAppConfig from '@utils/getAppConfig';
import { setMyLeadTrackingParams } from '@utils/myLead';
import setUpInterceptor from '@utils/setUpInterceptor';
import App from '@views/App/App';

import 'swiper/css';

import nextI18NextConfig from '../next-i18next.config.js';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import '@assets/reactDayPicker.css';
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';

if (process.env.NODE_ENV !== 'development') {
  require('source-map-support/register');
}

config.autoAddCss = false;

const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
};

const queryClient = new QueryClient(queryClientOptions);

const asyncStoragePersister = createAsyncStoragePersister({
  storage: typeof window !== 'undefined' ? AsyncStorage : undefined,
});

persistQueryClient({
  queryClient,
  persister: asyncStoragePersister,
});

let isMountedInterceptor = false;

(function setUpServiceWorker() {
  if (typeof window === 'undefined' || !('serviceWorker' in navigator)) {
    return;
  }

  const isWorkboxAvailable = 'workbox' in window;

  if (isWorkboxAvailable) {
    window.workbox.register();
  }

  const currentServiceWorker = navigator.serviceWorker.controller?.scriptURL;

  if (!currentServiceWorker || currentServiceWorker?.includes('/sw.js')) {
    return;
  }

  navigator.serviceWorker
    .register('/service-worker.js')
    .then(registration => {
      registration.update();

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;

        installingWorker.onstatechange = () => {
          if (
            installingWorker.state === 'installed' &&
            navigator.serviceWorker.controller
          ) {
            installingWorker.postMessage({ action: 'skipWaiting' });
          }
        };
      };
    })
    .catch(error => {
      console.error('Service worker registration failed:', error);
    });

  navigator.serviceWorker.addEventListener('message', e => {
    if (e.data?.action === 'impersonatedForeignServiceWorkerActivation') {
      if (isWorkboxAvailable) {
        window.workbox.register().then(() => {
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    }
  });
})();

const MyApp = ({ Component, pageProps, router }) => {
  const store = useStore(state => state);

  if (!isMountedInterceptor) {
    setUpInterceptor(store);
    isMountedInterceptor = true;
  }

  useEffect(() => {
    // Capturing query settings for native app
    store.dispatch(
      setNativeAppConfig({
        isNativeApp: router?.query?.['isNativeApp'] === '1',
        disableTracking: router?.query?.['disableTracking'] === '1',
        useNativeBridge: router?.query?.['useNativeBridge'] === '1',
        isNativeBridgeDebugEnabled:
          router?.query?.['isNativeBridgeDebugEnabled'] === '1',
        disableThirdPartyLoginMethods:
          router?.query?.['disableThirdPartyLoginMethods'] === '1',
      })
    );

    store.dispatch(
      setAppConfig({
        brandName: pageProps.brandName,
        currencyCode: pageProps.currencyCode,
      })
    );

    //Capturing myLead tracking params
    const myLeadUserId = router?.query?.['mylead_user_id'];
    const myLeadClickId = router?.query?.['mylead_click_id'];

    if (myLeadUserId && myLeadClickId) {
      setMyLeadTrackingParams({ clickId: myLeadClickId, userId: myLeadUserId });
    }

    NProgress.configure({
      showSpinner: false,
    });

    Router.onRouteChangeStart = () => {
      NProgress.start();
    };

    Router.onRouteChangeComplete = () => {
      NProgress.done();
    };

    Router.onRouteChangeError = () => {
      NProgress.done();
    };
  }, []);

  useEffect(() => {
    const $body = document.body;
    $body.classList.add(pageProps.bodyClassName);

    const localeScript = document.createElement('script');
    localeScript.innerHTML = `window.__localeId__ = "${pageProps.locale}"`;
    $body.appendChild(localeScript);

    return () => {
      $body.classList.remove(pageProps.bodyClassName);
    };
  }, [pageProps.bodyClassName, pageProps.locale]);

  const {
    appDehydratedState = {},
    dehydratedState = {},
    ...restPageProps
  } = pageProps;

  const mergedDehydratedState = deepmerge(appDehydratedState, dehydratedState);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <TailwindGlobalStyles />
        <Hydrate state={mergedDehydratedState}>
          <App Component={Component} pageProps={restPageProps} store={store} />
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

MyApp.getInitialProps = async context => {
  const { publicRuntimeConfig } = getConfig();
  const { ctx, router } = context;
  const { req, res } = ctx;
  const isNextRedirect = req ? req.url.includes('_next') : true;

  const xForwardedFor = req.headers['x-forwarded-for'];
  let ip = req.ip ?? req.headers['x-real-ip'];
  if (!ip && xForwardedFor) {
    ip = xForwardedFor.split(',').at(0) ?? req.connection.remoteAddress;
  }
  if (xForwardedFor) {
    axios.defaults.headers.common['x-forwarded-for'] = xForwardedFor;
  }
  if (ip) {
    axios.defaults.headers.common['x-real-ip'] = ip;
  }

  if (
    isEmpty(BASE_URL) ||
    isNextRedirect ||
    router.asPath.includes('/images')
  ) {
    return { pageProps: { configError: false } };
  }

  try {
    const queryClient = new QueryClient();
    const [response, responseLanguages, responseOrderForm] = await Promise.all([
      getAppConfig(),
      getLanguages(),
      getOrderForm(),
    ]);

    queryClient.setQueryData(['stripeKeys'], {
      secret: null,
      key: null,
    });
    queryClient.setQueryData(['paymentCards'], []);
    queryClient.setQueryData(['order'], {});

    await queryClient.prefetchQuery({
      queryKey: ['appConfig'],
      queryFn: () => response,
    });
    await queryClient.prefetchQuery({
      queryKey: ['languages'],
      queryFn: () => responseLanguages,
    });
    await queryClient.prefetchQuery({
      queryKey: ['orderForm'],
      queryFn: () => responseOrderForm,
    });

    const { clientAllowedToChangeLanguage } = response.multinational;
    const defaultLocale = publicRuntimeConfig.defaultLocale;

    const routerLocale = router.locale;
    const cookieNextLocale = getCookie('NEXT_LOCALE', { req, res });

    let cookieLangExistInLangArray = false;
    if (cookieNextLocale) {
      cookieLangExistInLangArray = responseLanguages.some(
        ({ isoCode, enabled }) =>
          enabled && isoCode.toLowerCase() === cookieNextLocale.toLowerCase()
      );
    }

    const routerLangExistInLangArray = responseLanguages.some(
      ({ isoCode, enabled }) =>
        enabled && isoCode.toLowerCase() === routerLocale.toLowerCase()
    );

    let pageProps = {
      appDehydratedState: dehydrate(queryClient),
      configError: false,
      bodyTags: response.branding.bodyTags,
      brandName: response.branding.name,
      currencyCode: response.multinational?.defaultRegion?.currencyCode,
      gtmModule: response.modules?.GoogleTagManager || {},
    };

    // Added early return when 404 page
    if (ctx?.res?.statusCode === 404) {
      pageProps = {};
    } else if (
      !clientAllowedToChangeLanguage ||
      (clientAllowedToChangeLanguage &&
        !routerLangExistInLangArray &&
        !cookieLangExistInLangArray)
    ) {
      setCookie('NEXT_LOCALE', defaultLocale, {
        req,
        res,
        maxAge: TIME.MONTH_IN_SECONDS,
      });

      pageProps = { ...pageProps, locale: defaultLocale };
    } else if (
      cookieNextLocale &&
      routerLocale !== cookieNextLocale &&
      routerLocale === defaultLocale
    ) {
      ctx.res.writeHead(302, {
        Location: `/${cookieNextLocale}${router.asPath}`,
      });
      ctx.res.end();

      pageProps = {};
    } else {
      setCookie('NEXT_LOCALE', routerLocale, {
        req,
        res,
        maxAge: TIME.MONTH_IN_SECONDS,
      });

      pageProps = { ...pageProps, locale: routerLocale };
    }

    return { pageProps };
  } catch (error) {
    return {
      pageProps: {
        configError: !['/404', '/500'].includes(router.route),
      },
    };
  }
};

export default wrapper.withRedux(appWithTranslation(MyApp, nextI18NextConfig));
